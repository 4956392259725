<template>
  <div class="manual-qualification-popup">
    <div class="manual-qualification-popup-container" v-if="data">
      <b-row>
        <b-col>
          <div><strong>Created Date:</strong> {{ data.created_date }}</div>
        </b-col>
        <b-col>
          <div><strong>Type:</strong> {{ data.type }}</div>
        </b-col>
        <b-col>
          <div><strong>Application:</strong> {{ data.tech_data.application }}</div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group
            label="Qualify:"
            label-for="qualify"
            class="mb-0"
          >
            <b-form-select id="qualify" v-model="qualify" :options="qualify_options"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Service:"
            label-for="service"
            class="mb-0"
          >
            <b-form-select id="service" v-model="service" :options="service_options"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Location:"
            label-for="location"
            class="mb-0"
          >
            <b-form-select id="location" v-model="location" :options="location_options"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group
            label="Automate data:"
            label-for="automate_data"
            class="mb-0"
          >
            <b-form-textarea
              id="automate_data"
              v-model="automate_data"
              rows="12"
              class="bg-light"
              readonly
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group
            label="Raw Data:"
            label-for="raw_data"
            class="mb-0"
          >
            <b-form-textarea
              id="raw_data"
              v-model="raw_data"
              rows="12"
              class="bg-light"
              readonly
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  data() {
    return {
      data: null,
      qualify: '',
      service: '',
      location: '',
      qualify_options: ['Qualified', 'Disqualified', 'Unknown', 'Repeat Customer'],
      service_options: [],
      location_options: [],
      raw_data: '',
      automate_data: ''
    }
  },
  watch: {
    qualify(newVal) {
      (async() => {
        this.updateActivity(this.data._id, 'qualify', newVal)
      })();
    },
    service(newVal) {
      (async() => {
        this.updateActivity(this.data._id, 'service', newVal)
      })();
    },
    location(newVal) {
      (async() => {
        this.updateActivity(this.data._id, 'location', newVal)
      })();
    }
  },
  methods: {
    updateActivity(_id, field, value) {
      MtApi.updateActivity(_id, {
        event: 'update_activity',
        field: {
          name: `manual_data.${field}`,
          value: value
        }
      })
    }
  },
  async mounted() {
    this.data = this.$attrs.params.node.data
    this.raw_data = JSON.stringify(this.data.raw_data, null, 2)
    this.automate_data = JSON.stringify(this.data.automate_data, null, 2)
    this.service = this.data?.manual_data?.service
    this.location = this.data?.manual_data?.location
    this.qualify = this.data?.manual_data?.qualify
    this.location_options = this.data.platform_info.locations.split(', ')
    this.service_options = this.data.platform_info.services.split(', ')
  }
}
</script>
<style lang="scss" scoped>
.manual-qualification-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 90vh;
}
.manual-qualification-popup-container {
  margin: 25px;
}
</style>
